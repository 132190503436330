<template>
  <page-layout ref="layout">
    <template #breadcrumbs="{ state }">
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Selections" :to="{ name: 'all-state-selections' }" />
      <b-breadcrumb-item v-if="!state.loading && !state.error" :text="instrument.name" :to="{ name: 'all-state-selection', params: { slug: instrument.slug } }"/>
      <b-breadcrumb-item text="Print" active/>
    </template>



    <template #actions="{ }">
        <b-button variant="outline-primary" size="sm" :disabled="table.busy" @click="print()">
          <font-awesome-icon icon="fa-solid fa-print" class="mr-50"/> Print
        </b-button>

    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: applications.loading, desc: 'Loading Applications', loaded: applications.loaded},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>

    <template #content="{ state }">
      <b-alert show dismissible variant="primary" class="d-print-none">
        <b-icon-info-circle class="mr-50"/>
        This view is readonly. To make changes, please use the <u><b-link :to="{ name: 'all-state-selection', params: { slug: instrument.slug }}">Selections</b-link></u> view.
      </b-alert>
      <template v-if="state.error">
				<b-alert v-if="ui.error.show" show variant="danger">
					{{ ui.error.message }}
				</b-alert>
      </template>
      <template v-else>
        <b-card no-body class="table-card">
          <template #header>
            <div class="d-flex flex-grow-1 d-print-none">
              <b-input-group class="input-group-merge">
                <b-form-input
                    id="search-apps"
                    v-model="table.search"
                    :debounce="250"
                    autocomplete="off"
                    size="md"
                    placeholder="Search Applications"
                />
                <b-input-group-append v-if="table.search" is-text>
                  <feather-icon icon="XIcon" class="text-muted" @click="table.search = ''" />
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-append>
              </b-input-group>
            </div>
          </template>
          <template #default>
            <validation-observer ref="table-observer">
              <div id="table-container">
                <b-table-simple v-for="group in groups" :key="group.id" responsive caption-top>
                  <b-thead v-if="table.busy">
                    <b-tr>
                      <b-th colspan="8">
                        <div class="d-flex justify-content-between">
                          <div>{{ instrument.name }} - {{ group.text }}</div>
                          <div v-if="table.busy">
                            <b-spinner class="align-middle" small variant="primary" />
                          </div>
                        </div>
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-thead v-else>
                    <b-tr>
                      <b-th colspan="8">{{ instrument.name }} - {{ group.text }}</b-th>
                    </b-tr>
                    <b-tr v-if="table.busy">
                      <b-th class="col-student"></b-th>
                      <b-th class="col-festival"></b-th>
                      <b-th class="col-zone"></b-th>
                      <b-th class="col-score"></b-th>
                      <b-th class="col-local"><div></div></b-th>
                      <b-th class="col-state"><div></div></b-th>
                      <b-th class="col-ensemble"></b-th>
                      <b-th class="col-part"></b-th>
                    </b-tr>
                    <b-tr v-else>
                      <b-th class="col-student">Student</b-th>
                      <b-th class="col-festival">Festival</b-th>
                      <b-th class="col-zone">Zone</b-th>
                      <b-th class="col-score">Score</b-th>
                      <b-th class="col-local"><div>Local Rank</div></b-th>
                      <b-th class="col-state"><div>State Rank</div></b-th>
                      <b-th class="col-ensemble">Ensemble</b-th>
                      <b-th class="col-part">Part</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-if="table.busy" />
                  <b-tbody v-else>
                    <b-tr v-for="item in group.items" :key="item.id">
                      <b-td>
                        <span v-if="item.student">
                          {{ item.student.name.first }} {{ item.student.name.last }}
                        </span>
<!--                        <span v-else class="text-danger">
                          No Student
                        </span>-->
                      </b-td>
                      <b-td>
                        <span v-if="item.festival">
                           {{ item.festival.name }}
                        </span>
<!--                        <span v-else class="text-danger">
                          No Festival
                        </span>-->
                      </b-td>
                      <b-td>
                        <span v-if="item.festival">
                           {{ getZoneName(item.festival) }}
                        </span>
<!--                        <span v-else class="text-danger">
                          No Zone
                        </span>-->
                      </b-td>
                      <b-td>{{ item.ranking.score }}</b-td>
                      <b-td>{{ item.ranking.local }}</b-td>
                      <b-td>{{ item.selection.state }}</b-td>
                      <b-td>
                        <span v-if="item.selection && item.selection.ensemble">
                            {{ getEnsembleCode(item.selection.ensemble) }}
                        </span>
<!--                        <span v-else class="text-danger">
                          No Ensemble
                        </span>-->
                      </b-td>
                      <b-td>{{ item.selection.part }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </validation-observer>
          </template>
        </b-card>
      </template>
    </template>

    <template #debug>
      <b-row>
        <b-col cols="4">
          <debug :title="`Group 1: ${group1.length }`">{{ group1 }}</debug>
        </b-col>
        <b-col cols="4">
          <debug :title="`Group 2: ${group2.length }`">{{ group2 }}</debug>
        </b-col>
        <b-col cols="4">
          <debug :title="`Group 3: ${group3.length }`">{{ group3 }}</debug>
        </b-col>
        <b-col cols="12">
          <debug :title="`Groups: ${groups.length }`">{{ groups }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import vSelect from 'vue-select'
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';

import {
	getApplicationDetails,
	getInstrument,
	getInstrumentBySlug,
	getUser,
	listApplications,
} from './selection-table';

import notify from '@/mixins/notify.mixin';
import OverlayLoading from '@/components/OverlayLoading.vue';
import Fuse from 'fuse.js';
import Ripple from 'vue-ripple-directive';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ApplicationDetails from '@/views/all-state/application/ApplicationDetails.vue';
import ApplicationStudent from '@/views/all-state/application/ApplicationStudent.vue';
import ApplicationQuestions from '@/views/all-state/application/ApplicationQuestions.vue';
import ApplicationGrading from '@/views/all-state/application/ApplicationGrading.vue';
import popper from '@/mixins/popper.mixin';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import TableRowOptions from '@/components/TableRowOptions.vue';
import settingsMixin from '@/mixins/settings.mixin';


export default {
  directives: {
    Ripple,
  },
  filters: {
    date(value) {
      return value ? new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(value)) : null
    }
  },
  components: {
    TableRowOptions,
    BCardActions,
		ApplicationGrading,
		ApplicationDetails,
		ApplicationStudent,
		ApplicationQuestions,
    OverlayLoading,
    PageLayout,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [notify, popper, settingsMixin],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      user: null,
      instrument: null,
      ensembles: {
        loading: true,
        items: []
      },
      applications: {
        loading: false,
        loaded: 0
      },
      table: {
        busy: false,
        fields: [
					/*{ key: 'group', label: 'Group', class: 'page-break' },*/
          { key: 'student', label: 'Student', class: 'col-student page-break' },
          { key: 'festival.name', label: 'Festival', class: 'col-festival page-break' },
          { key: 'festival.zone.name', label: 'Zone', class: 'col-zone page-break' },
          { key: 'ranking.score', label: 'Score', class: 'col-score page-break' },
          { key: 'ranking.local', label: 'Local Rank', class: 'col-local page-break' },
          { key: 'selection.ranking.state', label: 'State Rank', class: 'col-state page-break' },
          { key: 'selection.ensemble', label: 'Ensemble', class: 'col-ensemble page-break' },
          { key: 'selection.part', label: 'Part', class: 'col-part page-break' },
          /*{ key: 'row-options', label: '', tdClass: 'align-middle', thClass: 'border-0', class: 'table-row-options' },*/
        ],
        filter: {
          group: null,
          ensemble: null,
          part: null
        },
        search: null,
        items: [],
        initialItems: [],
        subscription: {
          onUpdate: null
        },
      },
      save: {
        complete: false,
        processing: false,
        status: '',
        changes: [],
        computedChanges: [],
        errors: [],
        progress: 0,
        processed: []
      },
      ui: {
        layout: {
          showLeftColumn: true,
        },
        changes: {
          expanded: false
        },
        processed: {
          expanded: true
        },
        potentialIssues: {
          expanded: false
        },
        error: {
            show: false,
            message: ''
        }
      },
      rules: {
        state: { required: false, numeric: true, min_value: 0, max_value: 999 },
        ensemble: { required: false },
        part: { required: false },
      },
      icon: 'fas fa-check',
    }
  },
  computed: {
    sortedItems() {
      if(this.table.busy || this.table.items.length === 0) {
        return []
      }
      const fuse = new Fuse(this.table.items, {
        useExtendedSearch: true,
        threshold: 0.2,
        keys: [
          'id',
          'group',
          'selection.ensemble',
          'selection.part',
          'student.id',
          'student.name.full',
        ]
      })

      const query = { $and: [ ] }
      if(this.table.filter.group) { query.$and.push({group: `'${this.table.filter.group}` }) }
      if(this.table.filter.ensemble) { query.$and.push({'selection.ensemble': `'${this.table.filter.ensemble}` }) }
      if(this.table.filter.part) { query.$and.push({'selection.part': `'${this.table.filter.part}` }) }

      if(this.table.search) {
        query.$and.push({
          $or: [
            { id: `'${this.table.search}` },
            { 'student.id': `'${this.table.search}` },
            { 'student.name.full': `'${this.table.search}`}
          ]
        })
      }
      let items;
      if(query.$and.length) {
        items = fuse.search(query).map(({ item }) => item).sort(this.compareItems())
      }
      else {
        items = this.table.items.slice().sort(this.compareItems())
      }

      return items.map((item, index) => ({...item,
        tabIndex: {
          state: (index + 1000),
            ensemble: (index + 1000) + this.table.items.length,
            part: (index + 1000) + (this.table.items.length * 2)
        }
      }))
    },
    groups() {
      if(this.groupOther.length > 0) {
        return [
          { id: 1, text: 'Group 1', items: this.group1 },
          { id: 2, text: 'Group 2', items: this.group2 },
          { id: 3, text: 'Group 3', items: this.group3 },
          { id: 3, text: 'Group 4', items: this.groupOther },
        ]
      }

      return [
        { id: 1, text: 'Group 1', items: this.group1 },
        { id: 2, text: 'Group 2', items: this.group2 },
        { id: 3, text: 'Group 3', items: this.group3 },
      ]
    },
    group1() {
      return this.sortedItems.filter(item => item.group === 1)
    },
    group2() {
      return this.sortedItems.filter(item => item.group === 2)
    },
    group3() {
      return this.sortedItems.filter(item => item.group === 3)
    },
    groupOther() {
      return this.sortedItems.filter(item => item.group !== 1 && item.group !== 2 && item.group !== 3)
    },
  },
	watch: {
		currentUser(value) {
			this.user = value
		},
    'table.items'() { },
	},
  async mounted() {
		await this.getCurrentUser()
    await this.getInstrument()
    await this.$nextTick(() => {
      setTimeout( () => { this.$refs.layout.state.loading = false }, 500);
    })
    if(!this.$refs.layout.state.error) {
      await this.listApplications()
      window.print()
    }
  },

  methods: {
    async refresh() {
      await this.listApplications()
    },
    print() {
      window.print()
    },
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    },
    async getInstrument() {
      if(this.id) {
        const response = await API.graphql(graphqlOperation(getInstrument, { id: this.id }));
        this.instrument = response.data.getInstrument
      }
      else {
        const response = await API.graphql(graphqlOperation(getInstrumentBySlug, { slug: this.slug }));
        // eslint-disable-next-line prefer-destructuring
        this.instrument = response.data.getInstrumentBySlug.items[0]
      }
      if(this.instrument) {
        if(this.user.groups.includes('NYSSMA_Chair')) {
          //if the user.ensembleIds doesn't have any matches with the instrument.ensembles.items -> item.id then return an error
          const matches = this.instrument.ensembles.items.filter(item => this.user.ensembleIds.includes(item.ensemble.id))
          if(matches.length === 0) {
              this.$refs.layout.state.error = true
              this.ui.error.show = true
              this.ui.error.message = 'No matching ensemble - You do not have access to this selection.'
          }
        }
        else if(this.user.groups.includes('NYSSMA_Committee')) {
          if(!this.user.instrumentIds.includes(this.instrument.id)) {
              this.$refs.layout.state.error = true
              this.ui.error.show = true
              this.ui.error.message = 'No matching instrument - You do not have access to this selection.'
          }
        }

        //set ensembles from instrument
        this.ensembles.items = this.instrument.ensembles.items
            .filter(item => item.ensemble.state.enabled)
            .map(item => item.ensemble)
            .sort((a, b) => a.name === 'Alternate' || a.name.localeCompare(b.name));
        this.ensembles.loading = false
      }
      else {
          this.$refs.layout.state.error = true
          this.ui.error.show = true
          this.ui.error.message = 'Invalid Selection'
      }
    },
    async listApplications(nextToken, pagedApplications) {
      this.table.busy = true
      this.applications.loading = true
      const applications = pagedApplications || []
      const input = {
        limit: 500,
        nextToken: nextToken,
        instrumentId: this.instrument.id, //sub filter
        filter: {
          applicationInstrumentId: { eq: this.instrument.id },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
      }

      const response = await API.graphql(graphqlOperation(listApplications, input));
      applications.push(...response.data.listApplications.items)

      this.applications.loaded = applications.length
      if(response.data.listApplications.nextToken) {
        await this.listApplications(response.data.listApplications.nextToken, applications)
      }
      else {
        this.applications.loading = false
        this.table.items = this.mapApplications(applications.filter(app => app.ranking.score > 0 && app.ranking.local > 0))
        this.table.busy = false
      }
    },


		async getApplicationDetails(item) {
			if(item.hasDetails === false) {
				const response = await API.graphql(graphqlOperation(getApplicationDetails, { id: item.id } ));
				const application = response.data.getApplication
				if(application) {
					item.student.dob = application.student.dob
					item.student.grade = application.student.grade
					item.student.address = application.student.address
					item.student.email = application.student.email
					item.form = application.form
					item.questions = application.questions
					item.grading = application.grading
					item.recommendation = application.recommendation
					item.comments = application.comments
				}
				item.hasDetails = true
			}
		},

    mapApplications(items) {
      const sortedItems = items.sort((a, b) =>
          b.ranking.score - a.ranking.score
          || a.ranking.local - b.ranking.local
          || a.student.name.last.localeCompare(b.student.name.last)
          || a.student.name.first.localeCompare(b.student.name.first))

      return sortedItems.map((item) => this.mapApplication(item));
    },
    mapApplication(item) {
      if(!item) return null
      return {
        id: item.id,
        group: this.getGroup(item.ranking),
        student: {
          id: item.student?.id,
          name: {
            first: item.student?.name?.first,
            last: item.student?.name?.last,
            full: `${item.student?.name?.first} ${item.student?.name?.last}`
          },
          school: item.student.school
        },
				form: item?.form,
        instrument: item?.instrument,
        festival: item?.festival,
				questions: item?.questions,
				grading: item?.grading,
        ranking: item?.ranking,
        selection: {
          id: item?.selection?.id || null,
          part: item?.selection?.part || null,
          state: item?.selection?.ranking?.state || null,
          ensemble: item?.selection?.ensemble?.id || null,
          accepted: item?.selection?.accepted || null,
          invoice: item?.selection?.selectionInvoiceId || null
        },
        otherApplications: item.student.applications.items.filter(otherApp => otherApp.id !== item.id && otherApp.selection).map(otherApp => ({
          id: otherApp.id,
          instrument: otherApp.instrument?.name,
          festival: otherApp.festival?.name,
          ranking: otherApp.ranking,
          selection: {
            id: otherApp?.selection?.id,
            part: otherApp?.selection?.part,
            state: otherApp?.selection?.ranking?.state,
            ensemble: otherApp?.selection?.ensemble?.name,
            accepted: otherApp?.selection?.accepted,
            invoice: otherApp?.selection?.selectionInvoiceId
          }
        })),
				hasDetails: false, //used to show/hide and load additional application details
      }
    },
    compareItems() {
      return (a, b) =>
          b.ranking.score - a.ranking.score
          || a.ranking.local - b.ranking.local
          || a.student.name.last.localeCompare(b.student.name.last)
          || a.student.name.first.localeCompare(b.student.name.first)
    },


    /** Input **/
    fromToText(from, to) {
      return `from ${from || 'null'} to ${to || 'null'}`
    },

    /** Util **/
    getGroup(ranking) {
      if(ranking.score === 100 && ranking.local === 1) return 1
      if((ranking.score === 100 && ranking.local > 1) || (ranking.score === 99 && ranking.local === 1)) return 2
      if((ranking.score === 99 && ranking.local > 1) || (ranking.score < 99 && ranking.local > 0)) return 3
      return 0
    },
    getGroupClass(item, type) {
      if(item && !this.table.filter) {
        if(item.group === 1) return 'page-break'
        if(item.group === 2) return 'bg-light page-break'
        if(item.group === 3) return 'tr-group-3 page-break'
        return ''
      }
      return null
    },
    getEnsembleCode(id) {
      return this.ensembles.items.find(item => item.id === id)?.code || ''
    },
    getEnsembleName(id) {
      return this.ensembles.items.find(item => item.id === id)?.name || null
    },
    getZoneName(festival) {
      if(festival === null || festival.zone === null) return null
      const prefix = 'Zone ';
      if (festival.zone.name.startsWith(prefix)) {
        return festival.zone.name.substring(prefix.length);
      }
      return festival.zone.name
    },



    getParts(ensembleId) {
      const parts = []
      const ensemble = this.ensembles.items.find(item => item.id === ensembleId)
      if(ensemble) {
        for (let i = 1; i <= ensemble.parts; i += 1) {
          parts.push(i.toString())
        }
      }
      return parts
    },
    filterEnsembles(options, search) {
      const fuse = new Fuse(options, {
        keys: [ { name: 'code', weight: 1 }, { name: 'name', weight: 0.75} ],
        threshold: 0.2,
        shouldSort: true,
      })
      return search.length ? fuse.search(`${search}`).map(({ item }) => item) : fuse.list
    },
    getSaveIcon(save) {
      if(save.complete) {
        if(save.computedChanges.every(item => item.success)) return 'check-circle-fill'
        if(save.computedChanges.every(item => item.error)) return 'x-circle-fill'
        return 'slash-circle-fill'
      }
      return 'circle'
    },
    getSaveVariant(save) {
      if(save.complete) {
        if(save.computedChanges.every(item => item.success)) return 'success'
        if(save.computedChanges.every(item => item.error)) return 'danger'
        return 'warning'
      }
      return 'primary'
    },
    getSaveClass() {
      if(this.save.processing || this.save.complete) {
        if(this.$refs['processed-scrollbar']?.ps?.scrollbarYActive === true) {
          return 'mr-2'
        }
      }
      return ''
    },
    searchAndExpandItem(id) {
      this.table.search = id
      const row = this.table.items.find(item => item.id === id)
      if(row) {
        // eslint-disable-next-line no-underscore-dangle
        row._showDetails = true
      }
    },
    searchAndExpandItems(id) {
      this.table.search = id
      this.table.items.filter(item => item.student.id === id).forEach(item => {
        // eslint-disable-next-line no-underscore-dangle
        item._showDetails = true
      })
    },

    /** Validation **/
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getValidationClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return null
      return state ? 'is-valid' : 'is-invalid'
    },
    canUpdate(ref) {
      return new Promise((resolve, reject) => {
        this.$refs[ref].validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  }
}
</script>

<style>
  .td-details-row {
    border-top: 0!important;
    padding: 0!important;
  }

  .table.b-table > tbody > tr.b-table-details > td {
    border-top: none !important;
  }

  .b-table-details > td > table > tbody > tr:first-child > td {
    border-top: 0!important;
  }

  .tr-group-2 {
    background-color: #f5f5f5!important;
  }
  .rounded-bottom-only {
    border-radius: 0 0 0.357rem 0.357rem;
  }

  .alert .list-group .list-group-item:hover {
    background-color: #fff;
  }
  .ps-changes {
    max-height: 300px;
  }
  .ps--active-y.ps-changes {
    padding-right: 1.5rem
  }
  .ps-processed {
    max-height: 50vh;
  }
  .ps--active-y.ps-processed {
    padding-right: 1.5rem
  }
  .alert .list-group .list-group-item:hover {
    background-color: #fff;
  }


  @media (max-width: 1400px) {
    .table th.col-local > div {
      display: none;
    }

    .table th.col-local::after {
      content: "Local";
      display: block;
    }
    .table th.col-state > div {
      display: none;
    }

    .table th.col-state::after {
      content: "State";
      display: block;
    }
  }


	@media print {
		.table th.col-local > div {
			display: none;
		}
		.table th.col-local::after {
			content: "LR";
			display: block;
		}
		.table th.col-state > div {
			display: none;
		}
		.table th.col-state::after {
			content: "SR";
			display: block;
		}

	}

</style>

<style lang="scss" scoped>
    ::v-deep {
      .col-student {
        width: 20%;
        vertical-align: middle !important;
      }
      .col-festival {
        //width: 10%;
        vertical-align: middle !important;
      }
      .col-score {
        //width: 10%;
        vertical-align: middle !important;
      }
      .col-local {
        //width: 10%;
        vertical-align: middle !important;
      }
      .col-state {
        width: 10%;
        vertical-align: middle !important;
      }
      .col-ensemble {
        width: 20%;
        padding-left: 0!important;
        padding-right: 0!important;
        vertical-align: middle !important;
      }
      .col-part {
        width: 10%;
        vertical-align: middle !important;
      }

      @media (max-width: 1530px) {
        /*.media-aside {
          display: none!important;
          margin-right:0!important;
        }
        .b-avatar {
          display: none!important;
        }*/
        .col-state {
          width: 12%!important;
        }
        .col-ensemble {
          width: 22%!important;
        }
        .col-part {
          width: 12%!important;
        }
        .student-school {
          display: none!important;
        }
        .festival-prefix {
          display: none!important;
        }
      }

      @media print {
        table {
          page-break-after: always;
        }

        table * {
          font-size: 12px!important;
        }

        .td-details-row {
          display: none!important;
        }

        thead {
          page-break-before:unset!important;
          page-break-inside: unset!important;
          page-break-after:unset!important;
        }

        .card .card-header {
          padding-top: 0!important;
        }
        .form-control {
          border: 0!important;
        }
        .vs__dropdown-toggle {
          border: 0!important;
        }
        .vs__actions {
          display: none!important;
        }
        .media-aside {
          margin-right: 0;
        }
        .b-avatar {
          display: none;
        }
        .col-student {
          width: auto!important;
        }
        .col-festival {
          width: auto!important;
        }
        .col-score {
          width: auto!important;
        }
        .col-local {
          width: auto!important;
        }
        .col-state {
          width: auto!important;
        }
        .col-ensemble {
          width: auto!important;
        }
        .col-part {
          width: auto!important;
        }
      }
    }

    /*.v-select::v-deep {
      .vs__selected-options {
        flex-wrap: nowrap;
        max-width: calc(100% - 40px); !* change this to `- 40px` if you're supporting a `clearable` field; I was not *!
      }
      .vs__selected {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
      }
    }*/
</style>
